<template>
  <div class="overlay-spinner">
    <div class="overlay-loader" ref="overlayLoader"></div>

    <div class="spinner-wrapper loader-spinner-wrapper" ref="spinnerWrapper">
      <spinner class="loader" :size="size" :color="color" :depth="depth"/>
    </div>
  </div>
</template>

<script>
  import Spinner from "vue-spinner-component/src/Spinner";
  import Tether from 'tether/dist/js/tether';
  import {defineComponent} from "vue";

  export default defineComponent({
    props: {
      size: {
        default: 40,
        type: Number,
      },
      depth: {
        default: 5,
        type: Number,
      },
      color: {
        default: '#389ef7',
        type: String,
      },
    },

    components: {
      Spinner
    },

    data() {
      return {
        tether: null,
        updatePosition: null,
      };
    },

    mounted() {
      this.tether = new Tether({
        element: this.$refs.spinnerWrapper,
        target: this.$refs.overlayLoader,
        attachment: 'middle center',
        targetAttachment: 'middle center',
        targetModifier: 'visible',
      });

      // keep updating position for 2 seconds
      this.updatePosition = setInterval(this.tether.position, 100);

      setTimeout(() => {
        clearInterval(this.updatePosition);
        this.updatePosition = null;
      }, 2000);
    },

    beforeUnmount() {
      this.tether.destroy();
      document.querySelector('.loader-spinner-wrapper').remove();
    },
  });
</script>

<style scoped>
  .overlay-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.9;
  }

  .spinner-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
  }
</style>
