import Swal from "sweetalert2/dist/sweetalert2.min.js";

type ConfirmDialogOptions = {
  text: string;
  type: string;
  title?: string;
  confirmButtonText?: string;
  icon?: string;
};

class ConfirmDialog {
  static show(options: ConfirmDialogOptions) {
    const bsType = options.type === 'error' ? 'danger' : options.type;

    return Swal.fire({
      title: options.title || null,
      text: options.text,
      icon: options.icon || options.type,
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: options.confirmButtonText || 'Ok',
      customClass: {
        confirmButton: "btn fw-bold btn-"+bsType,
        cancelButton: "btn fw-bold btn-secondary",
        icon: `text-${bsType} border-${bsType}`,
      }
    }).then(result => {
      return result.isConfirmed;
    });
  }
}

export default ConfirmDialog;
